/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { __spreadArray } from "tslib";
import { ListenEvent } from '../interfaces';
import { of, merge, from } from 'rxjs';
import { validateEventsArray } from '../utils';
import { fromRef } from '../fromRef';
import { switchMap, scan, distinctUntilChanged, map } from 'rxjs/operators';
import { changeToData } from '../object';
import { get as databaseGet } from 'firebase/database';
export function stateChanges(query, options) {
    if (options === void 0) { options = {}; }
    var events = validateEventsArray(options.events);
    var childEvent$ = events.map(function (event) { return fromRef(query, event); });
    return merge.apply(void 0, childEvent$);
}
function get(query) {
    return from(databaseGet(query)).pipe(map(function (snapshot) {
        var event = ListenEvent.value;
        return { snapshot: snapshot, prevKey: null, event: event };
    }));
}
export function list(query, options) {
    if (options === void 0) { options = {}; }
    var events = validateEventsArray(options.events);
    return get(query).pipe(switchMap(function (change) {
        if (!change.snapshot.exists()) {
            return of([]);
        }
        var childEvent$ = [of(change)];
        events.forEach(function (event) {
            childEvent$.push(fromRef(query, event));
        });
        return merge.apply(void 0, childEvent$).pipe(scan(buildView, []));
    }), distinctUntilChanged());
}
/**
 * Get an object mapped to its value, and optionally its key
 * @param query object ref or query
 * @param keyField map the object key to a specific field
 */
export function listVal(query, options) {
    if (options === void 0) { options = {}; }
    return list(query).pipe(map(function (arr) {
        return arr.map(function (change) { return changeToData(change, options); });
    }));
}
function positionFor(changes, key) {
    var len = changes.length;
    for (var i = 0; i < len; i++) {
        if (changes[i].snapshot.key === key) {
            return i;
        }
    }
    return -1;
}
function positionAfter(changes, prevKey) {
    if (prevKey == null) {
        return 0;
    }
    else {
        var i = positionFor(changes, prevKey);
        if (i === -1) {
            return changes.length;
        }
        else {
            return i + 1;
        }
    }
}
function buildView(current, change) {
    var snapshot = change.snapshot, prevKey = change.prevKey, event = change.event;
    var key = snapshot.key;
    var currentKeyPosition = positionFor(current, key);
    var afterPreviousKeyPosition = positionAfter(current, prevKey || undefined);
    switch (event) {
        case ListenEvent.value:
            if (change.snapshot && change.snapshot.exists()) {
                var prevKey_1 = null;
                change.snapshot.forEach(function (snapshot) {
                    var action = {
                        snapshot: snapshot,
                        event: ListenEvent.value,
                        prevKey: prevKey_1
                    };
                    prevKey_1 = snapshot.key;
                    current = __spreadArray(__spreadArray([], current), [action]);
                    return false;
                });
            }
            return current;
        case ListenEvent.added:
            if (currentKeyPosition > -1) {
                // check that the previouskey is what we expect, else reorder
                var previous = current[currentKeyPosition - 1];
                if (((previous && previous.snapshot.key) || null) !== prevKey) {
                    current = current.filter(function (x) { return x.snapshot.key !== snapshot.key; });
                    current.splice(afterPreviousKeyPosition, 0, change);
                }
            }
            else if (prevKey == null) {
                return __spreadArray([change], current);
            }
            else {
                current = current.slice();
                current.splice(afterPreviousKeyPosition, 0, change);
            }
            return current;
        case ListenEvent.removed:
            return current.filter(function (x) { return x.snapshot.key !== snapshot.key; });
        case ListenEvent.changed:
            return current.map(function (x) { return (x.snapshot.key === key ? change : x); });
        case ListenEvent.moved:
            if (currentKeyPosition > -1) {
                var data = current.splice(currentKeyPosition, 1)[0];
                current = current.slice();
                current.splice(afterPreviousKeyPosition, 0, data);
                return current;
            }
            return current;
        // default will also remove null results
        default:
            return current;
    }
}
